import styled from "styled-components"

const TableData = styled.td`
  text-align: left;
  width: ${props => (props.width ? props.width : "75%")};
  padding: 8px 0;
  white-space: ${props => (props.whiteSpace ? "nowrap" : "unset")};

  &:nth-child(2) {
    text-align: right;
    font-weight: bold;
    width: ${props =>
      props.widthSecondColumn ? props.widthSecondColumn : "25%"};
    vertical-align: top;
  }
`

export default TableData
