import styled from "styled-components"
import theme from "../../themes"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const HeroButton = styled(AnchorLink)`
  position: absolute;
  color: black;
  display: block;
  text-align: center;
  font-size: ${theme.main.sizes.typography.mobile.p};
  font-weight: bold;
  z-index: 10;
  bottom: ${props => (props.bottom ? props.bottom : "10%")};
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  padding: 16px 16px;
  margin: 0 auto;
  display: block;
  width: 240px;
  border: none;
  border: 4px solid ${theme.main.colors.pink};
  text-decoration: none;
`
export default HeroButton
