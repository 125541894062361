import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import theme from "../../themes"

const LinkButton = styled(AnchorLink)`
  border: 2px solid black;
  border-radius: 140px;
  text-decoration: none;
  text-align: center;
  outline: none;
  font-size: ${theme.main.sizes.typography.mobile.p};

  display: ${props => (props.hidden ? "none" : "inline-block")};
  color: white;
  background: ${props =>
    props.background ? props.background : theme.main.colors.pinkDark};
  border-radius: 140px;
  padding: 8px 16px;
  outline: none;
  border: none;
`

export default LinkButton
