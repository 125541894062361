import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import H2 from "../components/H2"
import Container from "../components/Container"
import Hero from "../components/Hero"
import PricingTable from "../components/PricingTable"
import HeroImage from "../components/Hero/HeroImage"
import HeroButton from "../components/Hero/HeroButton"
import theme from "../themes"
import calendar_hero from "../images/calendar_hero.svg"
import turtle_hello from "../images/turtle_hello.svg"
import panda_umbrella from "../images/panda_umbrella.svg"
import panda_sleep from "../images/panda_sleep.svg"
import { graphql } from "gatsby"
import Table from "../components/PricingTable/Table"
import TableRow from "../components/TableRow"
import TableData from "../components/TableData"
import TableBody from "../components/TableBody"
import turtle_chill_circle from "../images/turtle_chill_circle.svg"
import AdditionalCosts from "../components/AdditionalCosts"
import { Grid } from "@material-ui/core"
import ImageBlock from "../components/ImageBlock"

const StandardPage = ({ data }) => (
  <Layout>
    <SEO title="Standard" />
    <Hero bgColor={theme.main.colors.greenLight}>
      <Container>
        <H1>Standard</H1>
        <p>Für Eltern mit geplanten Arbeitstagen.</p>
      </Container>
      <HeroImage width={"40%"} src={calendar_hero} />
      <HeroButton bottom={"20%"} to="/standard#orientation" stripHash>
        Mehr erfahren
      </HeroButton>
      <img
        src={turtle_hello}
        style={{
          position: "absolute",
          left: "0",
          bottom: "0",
          height: "70%",
        }}
      />
    </Hero>
    <Section id="orientation">
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ImageBlock
              src={turtle_chill_circle}
              width={"100%"}
              widthSm={"60%"}
              widthMd={"100%"}
              margin={"0 0 0 -30%"}
              marginSm={"0 0 0 -20%"}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <H2>Feste Betreeungstage. Einfacher Plan.</H2>
            <p>
              Das Standard Programm bietet den Erziehungsberechtigten feste
              Betreuungstage an. Diese werden vor Vertragsbeginn verbindlich
              vereinbart.
            </p>
            <p>
              Weiterhin besteht die Möglichkeit, zusätzliche Betreuungsstunden
              oder -tage anzufragen.
            </p>
          </Grid>
        </Grid>
      </Container>
    </Section>
    <Section id="pricing">
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <H2>
              Unsere Tarife sind transparent. Für einen ruhigen Schlaf ohne
              Überraschungen.
            </H2>
          </Grid>

          <Grid item xs={12} md={7} style={{ overflow: "hidden" }}>
            <ImageBlock
              src={panda_sleep}
              width={"120%"}
              widthSm={"100%"}
              widthMd={"100%"}
              margin={"0 0 40px 20%"}
              marginSm={"0 0 40px 30%"}
            />
          </Grid>
        </Grid>

        <Table>
          <TableBody>
            <TableRow>
              <TableData>
                <b>{data.strapiPricingStandard.title}</b>
              </TableData>
              <TableData>
                <b>{data.strapiPricingStandard.value}</b>
              </TableData>
            </TableRow>

            {console.log(data.strapiPricingStandard)}

            {data.strapiPricingStandard.data.map((item, index) => (
              <TableRow key={index}>
                <TableData>{item.title}</TableData>
                <TableData>{item.value}</TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>
              <TableData>
                <b>{data.strapiPricingStandard.title2}</b>
              </TableData>
              <TableData>
                <b>{data.strapiPricingStandard.value2}</b>
              </TableData>
            </TableRow>

            {console.log(data.strapiPricingStandard)}

            {data.strapiPricingStandard.data2.map((item, index) => (
              <TableRow key={index}>
                <TableData>{item.title}</TableData>
                <TableData>{item.value}</TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <AdditionalCosts />

        {console.log(data)}
      </Container>
    </Section>
  </Layout>
)

export default StandardPage

export const query = graphql`
  query {
    strapiPricingFlexi {
      title
      value
      data {
        title
        value
      }
      title2
      value2
      data2 {
        title
        value
      }
    }

    strapiPricingStandard {
      title
      value
      data {
        title
        value
      }
      title2
      value2
      data2 {
        title
        value
      }
    }
  }
`
