import { StaticQuery } from "gatsby"
import React from "react"
import Table from "../Table"
import TableBody from "../TableBody"
import TableData from "../TableData"
import TableRow from "../TableRow"
import P from "../P"
import H2 from "../H2"
import Button from "../Button"
import LinkButton from "../LinkButton"

const AdditionalCosts = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiPricingOthers {
            title
            value
            data {
              title
              value
            }
            info
          }
        }
      `}
      render={data => (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableData>
                  <b>{data.strapiPricingOthers.title}</b>
                </TableData>
                <TableData>
                  <b>{data.strapiPricingOthers.value}</b>
                </TableData>
              </TableRow>

              {data.strapiPricingOthers.data.map((item, index) => (
                <TableRow key={index}>
                  <TableData>{item.title}</TableData>
                  <TableData whiteSpace>{item.value}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />

          <P margin={"0 0 40px 0"}>{data.strapiPricingOthers.info}</P>
          <H2>Freie Plätze</H2>
          <P margin={"0 0 24px 0"}>
            Reservieren Sie jetzt Ihren Kita-Platz. Ihre Anfrage gilt nicht als
            verbindliche Anmeldung. Wir setzen uns in jedem Fall mit Ihnen in
            Verbindung.
          </P>
          <LinkButton to={"/buchen"}>Platz buchen</LinkButton>
        </>
      )}
    />
  )
}

export default AdditionalCosts
