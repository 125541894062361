import styled from "styled-components"
import { device } from "../../themes/device"

const HeroImage = styled.img`
  position: absolute;
  right: 0;
  bottom: ${props => (props.bottom ? props.bottom : "0")};
  width: ${props => (props.width ? props.width : "80%")};

  @media ${device.tablet} {
    width: ${props => (props.widthSm ? props.widthSm : "50%")};
  }

  @media ${device.laptop} {
    width: ${props => (props.widthMd ? props.widthMd : "35%")};
  }

  @media ${device.laptopL} {
    width: ${props => (props.widthMd ? props.widthMd : "30%")};
  }
`

export default HeroImage
