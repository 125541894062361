import styled from "styled-components"

const TableRow = styled.tr`
  text-align: left;

  &:nth-child(2) {
    text-align: right;
  }
`

export default TableRow
