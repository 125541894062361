import styled from "styled-components"
import theme from "../../themes"

const Table = styled.table`
  width: 100%;
  table-layout: auto;
  font-size: ${theme.main.sizes.typography.mobile.p};
  margin-bottom: 40px;
`

export default Table
