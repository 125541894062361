import styled from "styled-components"

const Header = styled.th`
  text-align: left;

  &:nth-child(2) {
    text-align: right;
  }
`

export default Header
