import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const Button = styled.button`
  display: ${props => (props.hidden ? "none" : "inline-block")};
  color: white;
  background: ${props =>
    props.background ? props.background : theme.main.colors.pinkDark};
  border-radius: 140px;
  padding: 8px 16px;
  outline: none;
  border: none;

  @media ${device.laptop} {
    padding: 16px;
  }
`

export default Button
