import styled from "styled-components"

const Hero = styled.section`
  position: relative;
  color: black;
  height: 90vh;
  box-sizing: border-box;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "80px")};
  background-color: ${props => (props.bgColor ? props.bgColor : "none")};
  padding-top: 32px;
  overflow: hidden;
`
export default Hero
