import styled from "styled-components"

const Data = styled.td`
  text-align: left;
  width: 75%;
  padding: 8px 0;

  &:nth-child(2) {
    text-align: right;
    font-weight: bold;
    width: 25%;
    vertical-align: top;
  }
`

export default Data
